{
  "name": "@carbon/react",
  "description": "React components for the Carbon Design System",
  "version": "1.64.0",
  "license": "Apache-2.0",
  "main": "lib/index.js",
  "module": "es/index.js",
  "repository": {
    "type": "git",
    "url": "https://github.com/carbon-design-system/carbon.git",
    "directory": "packages/carbon-react"
  },
  "bugs": "https://github.com/carbon-design-system/carbon/issues",
  "files": [
    "es",
    "lib",
    "scss",
    "index.scss",
    "icons/index.js",
    "icons/index.esm.js",
    "icons/index.d.ts",
    "icons/package.json",
    ".playwright/INTERNAL_AVT_REPORT_DO_NOT_USE.json",
    "telemetry.yml"
  ],
  "keywords": [
    "ibm",
    "carbon",
    "carbon-design-system",
    "components",
    "react"
  ],
  "publishConfig": {
    "access": "public",
    "provenance": true
  },
  "scripts": {
    "build": "yarn clean && node tasks/build-styles.js && node tasks/build.js",
    "clean": "rimraf es lib icons/index.js icons/index.d.ts icons/index.esm.js storybook-static",
    "postinstall": "ibmtelemetry --config=telemetry.yml",
    "storybook": "storybook dev -p 3000",
    "storybook:build": "storybook build"
  },
  "peerDependencies": {
    "react": "^16.8.6 || ^17.0.1 || ^18.2.0",
    "react-dom": "^16.8.6 || ^17.0.1 || ^18.2.0",
    "sass": "^1.33.0"
  },
  "dependencies": {
    "@babel/runtime": "^7.24.7",
    "@carbon/feature-flags": "^0.21.0",
    "@carbon/icons-react": "^11.47.0",
    "@carbon/layout": "^11.24.0",
    "@carbon/styles": "^1.63.0",
    "@floating-ui/react": "^0.26.0",
    "@ibm/telemetry-js": "^1.5.0",
    "classnames": "2.5.1",
    "copy-to-clipboard": "^3.3.1",
    "downshift": "8.5.0",
    "flatpickr": "4.6.13",
    "invariant": "^2.2.3",
    "lodash.debounce": "^4.0.8",
    "lodash.findlast": "^4.5.0",
    "lodash.omit": "^4.5.0",
    "lodash.throttle": "^4.1.1",
    "prop-types": "^15.7.2",
    "react-fast-compare": "^3.2.2",
    "react-is": "^18.2.0",
    "tabbable": "^6.2.0",
    "use-resize-observer": "^6.0.0",
    "window-or-global": "^1.0.1"
  },
  "devDependencies": {
    "@babel/core": "^7.24.7",
    "@babel/plugin-proposal-class-properties": "^7.18.6",
    "@babel/plugin-proposal-export-default-from": "^7.24.7",
    "@babel/plugin-proposal-export-namespace-from": "^7.18.9",
    "@babel/plugin-transform-react-constant-elements": "^7.24.7",
    "@babel/preset-env": "^7.24.7",
    "@babel/preset-react": "^7.24.7",
    "@babel/preset-typescript": "^7.24.7",
    "@carbon/test-utils": "^10.30.0",
    "@carbon/themes": "^11.38.0",
    "@figma/code-connect": "^1.0.4",
    "@rollup/plugin-babel": "^6.0.0",
    "@rollup/plugin-commonjs": "^26.0.0",
    "@rollup/plugin-node-resolve": "^15.0.0",
    "@rollup/plugin-typescript": "^11.0.0",
    "@storybook/addon-a11y": "^7.1.0",
    "@storybook/addon-actions": "^7.1.0",
    "@storybook/addon-docs": "^7.1.0",
    "@storybook/addon-essentials": "^7.1.0",
    "@storybook/addon-links": "^7.4.5",
    "@storybook/addon-storysource": "^7.1.0",
    "@storybook/react": "^7.1.0",
    "@storybook/react-webpack5": "^7.1.0",
    "@storybook/theming": "^7.1.0",
    "@types/react-is": "~18.3.0",
    "autoprefixer": "^10.4.0",
    "babel-loader": "^9.0.0",
    "babel-plugin-dev-expression": "^0.2.3",
    "babel-preset-carbon": "^0.6.0",
    "browserify-zlib": "^0.2.0",
    "browserslist-config-carbon": "^11.2.0",
    "clipboardy": "^2.1.0",
    "css-loader": "^7.0.0",
    "enquirer": "^2.3.6",
    "fast-glob": "^3.2.7",
    "fs-extra": "^11.0.0",
    "html-webpack-plugin": "^5.5.0",
    "mini-css-extract-plugin": "^2.4.5",
    "postcss": "^8.4.5",
    "postcss-loader": "^8.0.0",
    "process": "^0.11.10",
    "prop-types": "^15.7.2",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "remark-gfm": "^3.0.1",
    "requestanimationframe": "^0.0.23",
    "rimraf": "^5.0.0",
    "rollup": "^2.79.1",
    "rollup-plugin-strip-banner": "^3.0.0",
    "sass": "^1.51.0",
    "sass-loader": "^14.0.0",
    "storybook": "^7.1.0",
    "storybook-addon-accessibility-checker": "^3.1.61-rc.3",
    "stream-browserify": "^3.0.0",
    "style-loader": "^3.3.1",
    "typescript-config-carbon": "^0.2.0",
    "webpack": "^5.65.0",
    "webpack-dev-server": "^4.7.4"
  },
  "sideEffects": [
    "es/index.js",
    "lib/index.js",
    "src/index.js",
    "es/feature-flags.js",
    "lib/feature-flags.js",
    "src/feature-flags.js",
    "index.scss",
    "**/*.scss",
    "**/*.css"
  ]
}
